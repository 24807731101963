/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CustomerPersonalData,
  DocumentGroup,
  DocumentsToBeAccepted,
} from '@/api'
import { UserSessionStatusEnum } from '@/common/enums/UserSessionStatus'
import { LoginScreen, PublicCurrentUser } from '@/framework/auth'
import {
  getAuthSession,
  getCurrentUser,
  resetCurrentUser,
} from '@/framework/auth/server'
import { ExpiredRefreshTokenError } from '@/framework/auth/types'
import { logger } from '@/framework/logger'
import { getCommonServerSideProps } from '@/framework/server/common-server-side-props'
import { getSession } from '@/framework/sessions/server'
import { GetServerSidePropsContext } from 'next'

const nameSpaces = ['country', 'USM-Login', 'USM-Schemas']

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const error = ctx.query
  const session = await getSession(ctx.req, ctx.res)
  const authSession = await getAuthSession(session)
  let currentUser = await getCurrentUser(session)
  const [serverSideProps, redirect] = await getCommonServerSideProps({
    ctx,
    nameSpaces,
    isRedirectForAuthUser: true,
  })
  if (redirect) {
    return redirect
  }

  //This is done to remove 'registered' status if user comes from registration
  if (
    currentUser.status !== UserSessionStatusEnum.OFFLINE &&
    currentUser.status !== UserSessionStatusEnum.AUTHENTICATED &&
    currentUser.status !== UserSessionStatusEnum.AUTHENTICATING &&
    currentUser.status !== UserSessionStatusEnum.WITHOUT_ADDRESS &&
    currentUser.status !==
      UserSessionStatusEnum.AUTHENTICATED_WITHOUT_ACCEPTED_DOCUMENTS
  ) {
    currentUser = await resetCurrentUser(session)
  }

  if (error) {
    logger(session.id, `Server side login page error: ${JSON.stringify(error)}`)
  }

  Object.assign(serverSideProps.props.prefetch, {
    documentsToBeAccepted: authSession?.documentsToBeAccepted ?? null,
    personalDocuments: authSession?.response ?? null,
    personalData: authSession?.personalData ?? null,
  })

  Object.assign(serverSideProps.props, {
    error,
    navDisabled: true,
    fallback: {
      currentUser,
    },
  })
  return serverSideProps
}

export default function Login(props: {
  error: ExpiredRefreshTokenError
  prefetch: {
    currentUser: PublicCurrentUser
    documentsToBeAccepted: DocumentsToBeAccepted
    personalDocuments: DocumentGroup[]
    personalData: CustomerPersonalData
  }
}) {
  return (
    <LoginScreen
      error={props.error}
      personalData={props.prefetch.personalData}
      userStatus={props.prefetch.currentUser.status}
      personalDocuments={props.prefetch.personalDocuments}
      documentsToBeAccepted={props.prefetch.documentsToBeAccepted}
    />
  )
}
